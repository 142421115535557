<template>
<div class="modal fade" id="sendmessagetoclient" tabindex="-1" role="dialog" aria-labelledby="contact" aria-hidden="true">
    <div class="modal-dialog modal-lg  modal-dialog-centered " role="document">
        <div class="modal-content d-flex align-items-center" v-if="success">
            <successMessage :message="success" />
            <button @click="hideModal" class="__close__success__modal">Close</button>
        </div>
        <div class="modal-content pb-3" v-if="!success">
            <div class="modal-header pt-4 pb-2">
                <h5 class="modal-title " id="exampleModalLabel">
                   New Promo Code
                </h5>
            </div>
            <div class="modal-body m-0" >       
                <div class="row justify-content-center">
                    <div class="col-md-12">
                        <el-form :model="formData" :rules="rules" ref="ruleForm"  @submit.prevent.native="checkEnter">
                            <div class="row justify-content-center">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6 form_input_container">
                                            <el-form-item label="Promo Name" prop="code">
                                                <el-input type="text" placeholder="Promo Name" v-model="formData.code" />
                                            </el-form-item>
                                        </div>
                                        <div class="col-md-6 form_input_container">
                                            <el-form-item label="Service Type" prop="service_type">
                                                <el-select id="_select_sender_id"  style="width:100%; height:48px"
                                                    v-model="formData.service_type" filterable 
                                                    placeholder="Select option">
                                                    <el-option 
                                                        v-for="(service, key) in seriveType" :key="key" 
                                                        :label="service.name" :value="service.name"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h3 class="form_header">
                                               Expiry Date
                                            </h3>
                                        </div>
                                        <div class="col-md-4 form_input_container">
                                            <el-form-item label="Validity from" style="width:100%" prop="expiry_date">
                                                <el-date-picker 
                                                    v-model="formData.expiry_date"
                                                    type="datetime"
                                                    style="width:100%; height:50px!important;"
                                                    placeholder="Select date"
                                                    value-format="yyyy-MM-dd HH:mm"
                                                    >
                                                </el-date-picker>
                                            </el-form-item>
                                        </div>
                                        <div class="col-md-4 form_input_container">
                                            <el-form-item label="Validity to" prop="to">
                                                <el-date-picker
                                                    v-model="formData.to"
                                                    type="datetime"
                                                    style="width:100%; height:50px!important;"
                                                    placeholder="Select date"
                                                    value-format="yyyy-MM-dd HH:mm"
                                                    >
                                                </el-date-picker>
                                            </el-form-item>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h3 class="form_header">
                                                Promotion Details
                                            </h3>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form_input_container">
                                                        <el-form-item label="Percentage(%)" prop="percentage">
                                                            <el-input type="text" autocomplete="off" placeholder="Enter percentage"  v-model.number="formData.percentage"></el-input>
                                                        </el-form-item>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form_input_container">
                                                        <el-form-item label="Minimum Target">
                                                        <el-input type="text" autocomplete="off" placeholder="Enter minimum target"  v-model.number="formData.message"></el-input>
                                                        </el-form-item>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form_input_container">
                                                        <el-form-item label="Limits" prop="limit">
                                                        <el-input type="text" autocomplete="off" placeholder="Enter Limit "  v-model.number="formData.limit"></el-input>
                                                        </el-form-item>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </el-form>
                    </div>
                </div>
                <div class="row justify-content-center mb-4">
                    <div  class="col-md-12 modal__footer__btns d-flex justify-content-between">
                        <button  style="width:123px" class="cancel" @click="hideModal">Cancel</button>
                        <el-button style="" type="primary" :loading="loading" id="cancelinput" @click="submitAction('ruleForm', 'edit')">
                            Save Promotion
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import store from '@/state/store.js'
import $ from 'jquery'
export default {
    props: {
    },
    data(){
        return {
            success: '',
            error: '',
            loading: false,
            seriveType: [
                {
                    id:'1',
                    name:'SMS'
                },
                {
                    id:'2',
                    name:'VOICE'
                }
            ],
            formData: {
               novalidity:false,
               code:'',
               percentage:'',
               expiry_date:'',
               service_type:'',
               limit:'',
               to:'',
            },
            rules:{
                code:[
                    {required: true, message: 'Please Enter Promo Name.', trigger: 'change' },
                ],
                expiry_date:[
                    {required: true, message: 'Please Enter Start Date.', trigger: 'change' },
                ],
                service_type: [
                    {required: true, message: 'Please select Service Type.', trigger: 'change' },
                ],
                percentage: [
                    {required: true, message: 'Please Enter percentage.', trigger: 'change' },
                ],
                limit: [
                    {required: true, message: 'Please Enter Limit.', trigger: 'change' },
                ],
                to:[
                    {required: true, message: 'Please Enter Expiry Date.',trigger: 'change'}
                ]

            }
        }
    },
    methods: {
        hideModal(){
            $('#sendmessagetoclient').modal('hide')
            this.error = ''
            this.success = ''
            this.loading = false
            this.formData = {
                    code: '',
                    service_type: '',
                    expiry_date:'',
                    limit:'',
                    percentage: '',
                    to:''
        }
        },
        createPromoCode(){
            this.loading = true
             store.dispatch('admin/promocodes/createPromocode', this.formData)
            .then( message => {
                this.success = message
            }).catch(err => {
                if(err?.response?.data == undefined) {
                    this.errors = "Network error try again."
                }
                this.$message({
                    type:"error",
                    message:err.response.data.message
                })
            }).finally( () => this.loading = false)
        },
        submitAction(formName){
            this.$refs[formName].validate((valid) => {
            if (valid) {
                this.createPromoCode()
            }else {
            this.loading = false;
            return false;
          }
        });   
        }
    },
    computed:{
        industries() {
            return store.getters['admin/industries/industries']
        }
    },
    mounted() {
        this.error = ''
        this.success = ''
        this.loading = false
        this.formData = {
                code: '',
                service_type: '',
                expiry_date:'',
                limit:'',
                percentage: '',
                to:''
            }
        store.dispatch('admin/industries/getIndustryReporting')
    }
}
</script>
<style scoped>
.text_description{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #484A4F;
}
.__message_text{
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #8A8C8F;
    padding: 1em 0;
}
.form_header{
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #575761;
    padding-bottom: .5em;
    border-bottom: 1px solid #F3F3F4;
    padding-top: 1em;   
    }
</style>